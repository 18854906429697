<template>
  <div class="container-fluid no-scroll px-0">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center fw-bold fs-4 mb-3 mt-5">
          Wishlist
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ol class="list-group list-group-numbered">
            <li v-for="item in wishlist" v-bind:key="item.id" class="py-3 list-group-item d-flex justify-content-between align-items-center">
              <div class="ms-4 me-auto">
                <div class="fw-bold">{{item.wish_item}}</div>
                <div class="mt-2" style="font-size: 0.8rem; color:#777;">{{ item.create_at }}</div>
              </div>
              <span class="bg-main rounded-pill" @click="itemToRemoved = item" data-bs-toggle="modal" data-bs-target="#removeModal" style="cursor: pointer;">
                <img style="height: 2rem;" src="../../assets/svgs/shopping_list_ic_del_nor.svg">
              </span>
            </li>
          </ol>
        </div>
        <div class="col-12">
          <div class="bg-white my-5 p-5">
            <p class="fs-5 fw-bold">Add item to your wishlist</p>
            <AddWishlist :isLoggedIn="isLoggedIn ? true : false" @itemAdded="onItemAdded"></AddWishlist>
          </div>
        </div>
      </div>
      <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="removeModalLabel">Confirm</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Are you sure to delete wish item {{ itemToRemoved?.wish_item}}?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn text-white" style="background: #777" data-bs-dismiss="modal" @click="delWishlist(itemToRemoved.id)">Delete</button>
              <button type="button" class="btn bg-trans text-white" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AddWishlist from '../../components/wishlist/AddWishlist'

import {
  getWishlist,
  removeFromWishlist
} from '../../api/wishlist'

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Wishlist',

  components: {
    AddWishlist
  },

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)

    const isLoggedIn = computed(() => store.state.auth.loggedIn)
    return {
      user,
      isLoggedIn
    }
  },

  created () {
    this.loadWishlist()
  },

  data () {
    return {
      wishlist: [],
      itemToRemoved: undefined
    }
  },

  methods: {
    loadWishlist () {
      this.$loading.show('Loading your wishlist...')
      getWishlist().then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.wishlist = res.data.wishlist
        } else {
          this.$toast.error('Failed to load your wishlist, please retry later')
        }
      })
    },

    delWishlist (id) {
      removeFromWishlist({
        id: id
      }).then(res => {
        if (res.code === 0) {
          setTimeout(() => {
            this.loadWishlist()
          }, 200)
        } else {
          this.$toast.error('Failed to remove item from wishlist, please retry later')
        }
      })
    },

    onItemAdded () {
      this.loadWishlist()
    }
  }
}
</script>

<style lang="scss">
</style>
